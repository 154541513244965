"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.scoreFunctions = exports.EMPTY_SCORE = exports.LOWER_SECTION = exports.UPPER_SECTION = void 0;
exports.UPPER_SECTION = [
    'Aces',
    'Twos',
    'Threes',
    'Fours',
    'Fives',
    'Sixes',
];
exports.LOWER_SECTION = [
    'Chance',
    'Four of a Kind',
    'Full House',
    'Small Straight',
    'Large Straight',
    'Yacht',
];
exports.EMPTY_SCORE = {
    Aces: null,
    Twos: null,
    Threes: null,
    Fours: null,
    Fives: null,
    Sixes: null,
    Chance: null,
    'Four of a Kind': null,
    'Full House': null,
    'Small Straight': null,
    'Large Straight': null,
    Yacht: null,
    Bonus: null
};
var calcScoreFor = function (dice, value) {
    var count = dice.filter(function (d) { return d === value; }).length;
    return count * value;
};
var calcSum = function (dice) { return dice.reduce(function (acc, count) { return acc + count; }, 0); };
var getCounts = function (dice) {
    return dice.reduce(function (acc, d) {
        var _a;
        var _b;
        return (__assign(__assign({}, acc), (_a = {}, _a[d] = ((_b = acc[d]) !== null && _b !== void 0 ? _b : 0) + 1, _a)));
    }, {});
};
var scoreFunctions = {
    Aces: function (dice) { return calcScoreFor(dice, 1); },
    Twos: function (dice) { return calcScoreFor(dice, 2); },
    Threes: function (dice) { return calcScoreFor(dice, 3); },
    Fours: function (dice) { return calcScoreFor(dice, 4); },
    Fives: function (dice) { return calcScoreFor(dice, 5); },
    Sixes: function (dice) { return calcScoreFor(dice, 6); },
    Chance: function (dice) { return dice.reduce(function (acc, d) { return acc + d; }, 0); },
    'Four of a Kind': function (dice) {
        var counts = getCounts(dice);
        var maxCount = Math.max.apply(Math, Object.values(counts));
        if (maxCount >= 4) {
            return calcSum(dice);
        }
        else {
            return 0;
        }
    },
    'Full House': function (dice) {
        var counts = getCounts(dice);
        if (Object.values(counts).some(function (count) { return count === 3; }) &&
            Object.values(counts).some(function (count) { return count === 2; })) {
            return calcSum(dice);
        }
        else {
            return 0;
        }
    },
    'Small Straight': function (dice) {
        var counts = dice.reduce(function (acc, d) { return (acc |= 1 << (d - 1)); }, 0);
        if ((counts & 15) === 15 ||
            (counts & 30) === 30 ||
            (counts & 60) === 60) {
            return 30;
        }
        else {
            return 0;
        }
    },
    'Large Straight': function (dice) {
        var counts = dice.reduce(function (acc, d) { return (acc |= 1 << (d - 1)); }, 0);
        if ((counts & 31) === 31 || (counts & 62) === 62) {
            return 40;
        }
        else {
            return 0;
        }
    },
    Yacht: function (dice) {
        var counts = getCounts(dice);
        var maxCount = Math.max.apply(Math, Object.values(counts));
        if (maxCount === 5) {
            return 50;
        }
        else {
            return 0;
        }
    }
};
var scoreFunctionsValidated = Object.entries(scoreFunctions).reduce(function (acc, _a) {
    var _b;
    var section = _a[0], func = _a[1];
    return __assign(__assign({}, acc), (_b = {}, _b[section] = function (dice) {
        if (dice.length !== 5) {
            return null;
        }
        return func(dice);
    }, _b));
}, {});
exports.scoreFunctions = scoreFunctionsValidated;
