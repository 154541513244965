"use strict";
exports.__esModule = true;
exports.isEnd = exports.isUpdate = exports.isStart = exports.isHealth = exports.isError = void 0;
var isError = function (data) {
    return data.type === 'error';
};
exports.isError = isError;
var isHealth = function (data) {
    return data.type === 'health';
};
exports.isHealth = isHealth;
var isStart = function (data) {
    return data.type === 'start';
};
exports.isStart = isStart;
var isUpdate = function (data) {
    return data.type === 'update';
};
exports.isUpdate = isUpdate;
var isEnd = function (data) {
    return data.type === 'end';
};
exports.isEnd = isEnd;
