"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.DEFAULT_GAME = void 0;
var score_1 = require("./score");
exports.DEFAULT_GAME = {
    state: 'waiting',
    currentPlayer: 1,
    boardDice: [
        { id: 1, value: 1 },
        { id: 2, value: 1 },
        { id: 3, value: 1 },
        { id: 4, value: 1 },
        { id: 5, value: 1 },
    ],
    savedDice: [],
    players: [
        { id: 1, score: __assign({}, score_1.EMPTY_SCORE) },
        { id: 2, score: __assign({}, score_1.EMPTY_SCORE) },
    ],
    rollCount: 0,
    turn: 1
};
